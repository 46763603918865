import { UnauthenticatedTemplate } from '@azure/msal-react';
import { Box } from '@mui/material';
import LogInButton from './LoginButton';

const UnauthorizedApp = () => {
  return (
    <UnauthenticatedTemplate>
      <div className='app'>
        <main className='content'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <LogInButton />
          </Box>
        </main>
      </div>
    </UnauthenticatedTemplate>
  );
};

export default UnauthorizedApp;
