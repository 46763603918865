import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { tokens } from '../../theme';

export function SidebarAvatar() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb='25px'>
      <Box display='flex' justifyContent='center' alignItems='center'>
        <img
          alt='profile-user'
          width='100px'
          height='100px'
          src={`../../assets/klaudia.png`}
          style={{ cursor: 'pointer', borderRadius: '50%' }}
        />
      </Box>
      <Box textAlign='center'>
        <Typography variant='h2' color={colors.grey[100]} fontWeight='bold' sx={{ m: '10px 0 0 0' }}>
          Klaudia Dec
        </Typography>
        <Typography variant='h5' color={colors.greenAccent[500]}>
          Math Teacher (Admin)
        </Typography>
      </Box>
    </Box>
  );
}
