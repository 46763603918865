export function docDefinition(chartUrl) {
  return {
    content: [
      {
        columns: [
          {
            layout: 'lightHorizontalLines', // optional
            width: 'auto',
            table: {
              headerRows: 0,
              widths: [100, 'auto'],

              body: [
                [{ text: 'Przedmiot', bold: true }, 'Matematyka'],
                [{ text: 'Nauczyciel', bold: true }, 'Klaudia Dec'],
                [{ text: 'Rocznik', bold: true }, '1LO'],
                [{ text: 'Klasa / grupa', bold: true }, '1b'],
                [{ text: 'Liczba ankiet', bold: true }, '18'],
              ],
            },
          },
          {
            layout: '',
            width: '*',
            table: {
              headerRows: 0,
              widths: [120, '*'],
              body: [
                [
                  { text: 'PRZEKAZYWANIE WIEDZY', bold: true, alignment: 'center' },
                  '1 - Na lekcjach dowiaduję się dużo nowych rzeczy, czuję, że nauczyciel jest kompetentny w swoim przedmiocie',
                ],
                [
                  { text: 'ATRAKCYJNOŚĆ PRZEKAZU', bold: true, alignment: 'center' },
                  '2 - Nauczyciel przekazuje wiedzę w ciekawy sposób, inspiruje',
                ],
                [
                  { text: 'ORGANIZACJA LEKCJI', bold: true, alignment: 'center' },
                  '3 - Mam wrażenie, że zajęcia są dobrze zaplanowane, uporządkowane, nauczyciel wie, do czego zmierza',
                ],
                [
                  { text: 'ATMOSFERA NA LEKCJI', bold: true, alignment: 'center' },
                  '4 - Na lekcjach panuje atmosfera sprzyjająca pracy, nauczyciel potrafi pracować z grupą, reaguje na to, co się dzieje w klasie',
                ],
                [
                  { text: 'ŻYCZLIWE PODEJŚCIE DO UCZNIÓW', bold: true, alignment: 'center' },
                  '5 - Czuję, że mogę poprosić nauczyciela o pomoc i radę',
                ],
                [
                  { text: 'STOSUNEK DO UCZNIÓW I UCZENNIC', bold: true, alignment: 'center' },
                  '6 - Nauczyciel okazuje uczniom i uczennicom należny im szacunek, nie przekracza granic, na zajęciach czuję się bezpiecznie',
                ],
                [
                  { text: 'BEZSTRONNOŚĆ', bold: true, alignment: 'center' },
                  '7 - Nauczyciel jest bezstronny, nie narzuca uczniom własnych przekonań, szanuje ich poglądy',
                ],
                [
                  { text: 'OTWARTOŚĆ', bold: true, alignment: 'center' },
                  '8 - Nauczyciel jest otwarty na potrzeby uczniów, potrafi pójść na kompromis, przyjąć uzasadnioną krytykę',
                ],
                [
                  { text: 'ZAANGAŻOWANIE', bold: true, alignment: 'center' },
                  '9 - Nauczyciel angażuje się w prowadzenie lekcji',
                ],
                [
                  { text: 'STYMULOWANIE DO PRACY', bold: true, alignment: 'center' },
                  '10 - Nauczyciel stawia wymagania, stymuluje do pracy, zachęca do robienia trudnych rzeczy',
                ],
                [
                  { text: 'OCENIANIE', bold: true, alignment: 'center' },
                  '11 - Nauczyciel ocenia sprawiedliwie, wiem z czego wynikają moje oceny',
                ],
                [
                  { text: 'INFORMACJE ZWROTNE', bold: true, alignment: 'center' },
                  '12 - Nauczyciel udziela użytecznych dla mnie informacji zwrotnych',
                ],
                [
                  { text: 'PRZEJRZYSTOŚĆ REGUŁ', bold: true, alignment: 'center' },
                  '13 - Nauczyciel jasno formułuje swój system oceniania, czuję się dobrze poinformowany/a i wiem, czego się mogę spodziewać',
                ],
              ],
            },
          },
        ],
      },

      {
        image: 'chart1',
        width: 750,
      },
    ],
    defaultStyle: {
      fontSize: 9,
      columnGap: 20,
    },
    images: {
      chart1: chartUrl,
    },
    pageOrientation: 'landscape',
  };
}

export const chartDef = {
  type: 'bar',
  data: {
    datasets: [
      {
        fill: true,
        spanGaps: false,
        lineTension: 0.4,
        pointRadius: 3,
        pointHoverRadius: 3,
        pointStyle: 'circle',
        borderDash: [0, 0],
        barPercentage: 0.9,
        categoryPercentage: 0.8,
        data: [-1, 2, -1.4, 1.2, 1.4, 1.7, -1, 2, -1.4, 1.2, 1.4, 1.7, -1.1],
        type: 'bar',
        label: '',
        borderColor: '#4E79A7',
        backgroundColor: '#4E79A733',
        borderWidth: 3,
        hidden: false,
      },
    ],
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'],
  },
  options: {
    title: {
      display: false,
      position: 'top',
      fontSize: 12,
      fontFamily: 'sans-serif',
      fontColor: '#666666',
      fontStyle: 'bold',
      padding: 10,
      lineHeight: 1.2,
      text: 'Chart title',
    },
    layout: {
      padding: {},
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    legend: {
      display: false,
      position: 'top',
      align: 'center',
      fullWidth: true,
      reverse: false,
      labels: {
        fontSize: 12,
        fontFamily: 'sans-serif',
        fontColor: '#666666',
        fontStyle: 'normal',
        padding: 10,
      },
    },
    scales: {
      xAxes: [
        {
          id: 'X1',
          display: true,
          position: 'bottom',
          type: 'category',
          stacked: false,
          time: {
            unit: false,
            stepSize: 1,
            displayFormats: {
              millisecond: 'h:mm:ss.SSS a',
              second: 'h:mm:ss a',
              minute: 'h:mm a',
              hour: 'hA',
              day: 'MMM D',
              week: 'll',
              month: 'MMM YYYY',
              quarter: '[Q]Q - YYYY',
              year: 'YYYY',
            },
          },
          distribution: 'linear',
          gridLines: {
            display: true,
            color: 'rgba(0, 0, 0, 0.1)',
            borderDash: [0, 0],
            lineWidth: 1,
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
            tickMarkLength: 10,
            zeroLineWidth: 1,
            zeroLineColor: 'rgba(0, 0, 0, 0.25)',
            zeroLineBorderDash: [0, 0],
          },
          angleLines: {
            display: true,
            color: 'rgba(0, 0, 0, 0.1)',
            borderDash: [0, 0],
            lineWidth: 1,
          },
          pointLabels: {
            display: true,
            fontColor: '#666',
            fontSize: 10,
            fontStyle: 'normal',
          },
          ticks: {
            display: true,
            fontSize: 12,
            fontFamily: 'sans-serif',
            fontColor: '#666666',
            fontStyle: 'normal',
            padding: 0,
            stepSize: null,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            reverse: false,
          },
          scaleLabel: {
            display: false,
            labelString: 'Axis label',
            lineHeight: 1.2,
            fontColor: '#666666',
            fontFamily: 'sans-serif',
            fontSize: 12,
            fontStyle: 'normal',
            padding: 4,
          },
        },
      ],
      yAxes: [
        {
          id: 'Y1',
          display: true,
          position: 'left',
          type: 'linear',
          stacked: false,
          time: {
            unit: false,
            stepSize: 1,
            displayFormats: {
              millisecond: 'h:mm:ss.SSS a',
              second: 'h:mm:ss a',
              minute: 'h:mm a',
              hour: 'hA',
              day: 'MMM D',
              week: 'll',
              month: 'MMM YYYY',
              quarter: '[Q]Q - YYYY',
              year: 'YYYY',
            },
          },
          distribution: 'linear',
          gridLines: {
            display: true,
            color: 'rgba(0, 0, 0, 0.1)',
            borderDash: [0, 0],
            lineWidth: 1,
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
            tickMarkLength: 10,
            zeroLineWidth: 1,
            zeroLineColor: 'rgba(0, 0, 0, 0.25)',
            zeroLineBorderDash: [0, 0],
          },
          angleLines: {
            display: true,
            color: 'rgba(0, 0, 0, 0.1)',
            borderDash: [0, 0],
            lineWidth: 1,
          },
          pointLabels: {
            display: true,
            fontColor: '#666',
            fontSize: 10,
            fontStyle: 'normal',
          },
          ticks: {
            display: true,
            fontSize: 12,
            fontFamily: 'sans-serif',
            fontColor: '#666666',
            fontStyle: 'normal',
            padding: 0,
            stepSize: null,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            reverse: false,
          },
          scaleLabel: {
            display: false,
            labelString: 'Axis label',
            lineHeight: 1.2,
            fontColor: '#666666',
            fontFamily: 'sans-serif',
            fontSize: 12,
            fontStyle: 'normal',
            padding: 4,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'center',
        anchor: 'center',
        backgroundColor: '#eee',
        borderColor: '#ddd',
        borderRadius: 6,
        borderWidth: 1,
        padding: 4,
        color: '#666666',
        font: {
          family: 'sans-serif',
          size: 10,
          style: 'normal',
        },
      },
      datalabelsZAxis: {
        enabled: false,
      },
      googleSheets: {},
      airtable: {},
      tickFormat: '',
    },
    cutoutPercentage: 50,
    rotation: -1.5707963267948966,
    circumference: 6.283185307179586,
    startAngle: -1.5707963267948966,
  },
};
