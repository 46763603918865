import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import UnauthorizedApp from './scenes/global/UnauthorizedApp';
import AuthorizedApp from './scenes/global/AuthorizedApp';
import { AuthenticatedTemplate } from '@azure/msal-react';

const App = () => {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UnauthorizedApp />
        <AuthenticatedTemplate>
          <AuthorizedApp />
        </AuthenticatedTemplate>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
