import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner } from '../global/LoadingSpinner';
import { ApiContext } from '../../components/providers/ApiProvider';
import { useMutation, useQuery } from 'react-query';
import { useContext, useEffect, useRef, useState } from 'react';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomGridToolbar from '../../components/charts/CustomGridToolbar';
import Header from '../../components/Header';

const Classes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [classData, setClassData] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isNewOpen, setIsNewOpen] = useState(false);
  const [classToEdit, setClassToEdit] = useState({});
  const [teachersData, setTeachersData] = useState([]);
  const api = useContext(ApiContext);
  const navigate = useNavigate();

  const unpackTeachersData = (queryResp) => setTeachersData(queryResp?.data || []);
  const unpackClassData = (queryResp) => setClassData(queryResp?.data || []);

  useEffect(() => {
    setTeachersData(api.queryClient.getQueriesData('query-teachers')?.data || []);
    setClassData(api.queryClient.getQueriesData('query-classes')?.data || []);
  }, []);

  useQuery('query-teachers', { onSuccess: unpackTeachersData });
  const { isLoading, refetch: refetchClasses } = useQuery('query-classes', { onSuccess: unpackClassData });

  const mutationUpdateClass = useMutation({
    mutationKey: ['mutation-class-update'],
    onSuccess: (res) => refetchClasses(),
  });

  const mutationNewClass = useMutation({
    mutationKey: ['mutation-class-create'],
    onSuccess: (res) => refetchClasses(),
  });

  const editClass = function (classData) {
    console.log('classData: ', classData);
    setClassToEdit(classData);
    setIsEditOpen(true);
  };

  const handleClose = () => {
    setIsEditOpen(false);
    setIsNewOpen(false);
    setClassToEdit({});
  };

  const handleEditSave = (classUpdates) => {
    setIsEditOpen(false);
    console.log('updates: ', classUpdates);
    mutationUpdateClass.mutate({
      classId: classToEdit.id,
      classData: classUpdates,
    });
  };

  const newClass = function () {
    setIsNewOpen(true);
  };

  const handleNewSave = (newClassData) => {
    console.log('handleNewSave', newClassData);
    setIsNewOpen(false);
    mutationNewClass.mutate(newClassData);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.8,
      renderCell: ({ row: { code, id } }) => {
        return (
          <Button
            sx={{ minWidth: '100%' }}
            variant='contained'
            startIcon={<VisibilityIcon />}
            onClick={() => navigate(`/classes/${id}`)}
          >
            {code}
          </Button>
        );
      },
    },
    {
      field: 'startYearStr',
      headerName: 'Start Year',
      flex: 1,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      flex: 1,
    },
    {
      field: 'educator',
      headerName: 'Educator',
      flex: 1,
      renderCell: ({ row }) => {
        const role = row.role;
        return (
          <Grid container direction='row' justifyContent='space-around' alignItems='center'>
            {/* <Box
              flex
              width='60%'
              m='0 auto'
              p='5px'
              display='flex'
              justifyContent='center'
              backgroundColor={
                role === 'admin'
                  ? colors.greenAccent[600]
                  : role === 'manager'
                  ? colors.greenAccent[700]
                  : colors.greenAccent[700]
              }
              borderRadius='4px'
            >
              {role === 'admin' && <AdminPanelSettingsOutlinedIcon />}
              {role === 'manager' && <SecurityOutlinedIcon />}
              {role === 'user' && <LockOpenOutlinedIcon />}
              <Typography color={colors.grey[100]} sx={{ ml: '5px' }}>
                {row.educator.firstName} {row.educator.lastName}
              </Typography>
            </Box> */}
            {/* <Box /> */}
            <Button
              size='small'
              color='secondary'
              onClick={() => editClass(row)}
              variant='contained'
              endIcon={<ModeEditOutlineIcon />}
            >
              {row.educator.firstName} {row.educator.lastName}
            </Button>
          </Grid>
        );
      },
    },
  ];

  return (
    <>
      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <Box m='20px'>
          <Header title='Classes' subtitle="List of 2SLO's Classes" />
          <Button size='small' color='secondary' onClick={newClass} variant='contained'>
            <span>New Class</span>
          </Button>

          <Box
            m='40px 0 0 0'
            height='75vh'
            sx={{
              '& .MuiDataGrid-root': {
                border: 'none',
              },
              '& .MuiDataGrid-cell': {
                borderBottom: 'none',
              },
              '& .name-column--cell': {
                color: colors.greenAccent[300],
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: colors.blueAccent[700],
                borderBottom: 'none',
              },
              '& .MuiDataGrid-virtualScroller': {
                backgroundColor: colors.primary[400],
              },
              '& .MuiDataGrid-footerContainer': {
                borderTop: 'none',
                backgroundColor: colors.blueAccent[700],
              },
              '& .MuiCheckbox-root': {
                color: `${colors.greenAccent[200]} !important`,
              },
              '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid
              rows={classData}
              columns={columns}
              components={{ Toolbar: CustomGridToolbar }}
              hideFooterSelectedRowCount={true}
            />
          </Box>
        </Box>
      )}

      {isEditOpen && <ClassDialogEdit onSave={handleEditSave} onClose={handleClose} classData={classToEdit} />}
      {isNewOpen && <ClassDialogNew onSave={handleNewSave} onClose={handleClose} teachersData={teachersData} />}

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={mutationNewClass.isLoading || mutationUpdateClass.isLoading}
      ></Backdrop>
    </>
  );
};

const ClassDialogEdit = ({ onClose, onSave, classData }) => {
  const subjectRef = useRef();
  const [isInNowowiejska, setIsInNowowiejska] = useState(classData.branches?.includes('Nowowiejska') || false);
  const [isInSolec, setIsInSolec] = useState(classData.branches?.includes('Solec') || false);

  const onDialogSave = () => {
    console.log('subject inside', subjectRef.current.value);
    const newSubject = subjectRef.current.value;
    const subjectEdited =
      !(classData.subject === undefined && newSubject === '') && classData.subject !== subjectRef.current.value;

    const nBranchChange =
      (classData.branches?.includes('Nowowiejska') && !isInNowowiejska) ||
      (!classData.branches?.includes('Nowowiejska') && isInNowowiejska);
    const sBranchChange =
      (classData.branches?.includes('Solec') && !isInSolec) || (!classData.branches?.includes('Solec') && isInSolec);

    const classUpdates = {};
    if (subjectEdited) {
      classUpdates['subject'] = newSubject;
    }
    if (nBranchChange || sBranchChange) {
      const branches = [];
      if (isInNowowiejska) branches.push('Nowowiejska');
      if (isInSolec) branches.push('Solec');
      classUpdates['branches'] = branches;
    }

    console.log('was edited: ', classUpdates);

    if (!subjectEdited && !nBranchChange && !sBranchChange) {
      onClose();
    } else {
      onSave(classUpdates);
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth={'md'} open={true} onClose={onClose}>
      <DialogTitle>Edit Class</DialogTitle>
      <DialogContent>
        <DialogContentText>{"Update Class's details"}</DialogContentText>
        <Grid container direction='column' justifyContent='space-between'>
          <TextField
            sx={{ marginTop: 3 }}
            label='Name'
            defaultValue={classData.firstName + ' ' + classData.lastName}
            disabled
          />
          <TextField sx={{ marginTop: 3 }} label='Email' defaultValue={classData.email} disabled />
          <TextField
            sx={{ marginTop: 3 }}
            label='Subject'
            inputRef={subjectRef}
            defaultValue={classData.subject || ''}
          />

          <FormControl sx={{ marginTop: 3 }} component='fieldset' variant='standard'>
            <FormLabel component='legend'>Choose Branches</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isInNowowiejska}
                    onChange={() => {
                      setIsInNowowiejska((prevState) => !prevState);
                    }}
                    name='nowowiejska'
                  />
                }
                label='Nowowiejska'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isInSolec}
                    onChange={() => {
                      setIsInSolec((prevState) => !prevState);
                    }}
                    name='solec'
                  />
                }
                label='Solec'
              />
            </FormGroup>
            <FormHelperText>You can select both</FormHelperText>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onDialogSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

const ClassDialogNew = ({ onClose, onSave, teachersData }) => {
  const [unit, setUnit] = useState('');
  const [startYear, setStartYear] = useState(new Date().getFullYear());
  const [educatorId, setEducatorId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const onDialogSave = (event) => {
    if (!startYear || !educatorId || !unit) setAnchorEl(event.currentTarget);
    else onSave({ unit, startYear, educatorId });
  };

  const handleChange = (event) => {
    console.log('target', event.target);
    setEducatorId(event.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'save-blocked-popover' : undefined;

  return (
    <Dialog fullWidth={true} maxWidth={'md'} open={true} onClose={onClose}>
      <DialogTitle>New Class</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>{"Update Class's details"}</DialogContentText> */}
        <Grid container direction='column' justifyContent='space-between'>
          <TextField
            required
            sx={{ marginTop: 3 }}
            label='Start Year'
            defaultValue={startYear}
            type='number'
            onChange={(e) => setStartYear(e.target.value)}
          />
          <TextField required sx={{ marginTop: 3 }} label='Unit' onChange={(e) => setUnit(e.target.value)} />
          <FormControl sx={{ marginTop: 3 }} fullWidth>
            <InputLabel required id='educator-select-label'>
              Educator
            </InputLabel>
            <Select label='educator-select-label' onChange={handleChange} value={educatorId}>
              {teachersData.map((t) => {
                return (
                  <MenuItem key={t.id} value={t.id}>
                    {t.firstName} {t.lastName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button aria-describedby={id} onClick={onDialogSave}>
          Save
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
        </Popover>
      </DialogActions>
    </Dialog>
  );
};

export default Classes;
