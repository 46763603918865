import { Box, Button, Input, Select, MenuItem, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import * as yup from 'yup';
import Header from '../../components/Header';
import { ApiContext } from '../../components/providers/ApiProvider';

const NewSurveyForm = () => {
  const [classData, setClassData] = useState([]);
  const [teachersData, setTeachersData] = useState([]);
  const api = useContext(ApiContext);

  useQuery('query-teachers', {
    onSuccess: (res) => setTeachersData(res.data),
    onError: (err) => console.error('err', err),
  });

  useQuery('query-classes', {
    onSuccess: (res) => setClassData(res.data),
    onError: (err) => console.error('err', err),
  });

  const mutationSubmitSurvey = useMutation({
    mutationKey: ['mutation-submit-survey'],
    onSuccess: (res) => console.log(res),
  });

  const handleFormSubmit = (values) => {
    console.log('values', values);

    let formDataObj = new FormData();
    formDataObj.append('academicYear', values.academicYear);
    formDataObj.append('semester', values.semester);
    formDataObj.append('classId', values.class);
    formDataObj.append('teacherId', values.teacher);
    formDataObj.append('branch', values.branch);
    formDataObj.append('csvFile', values.csvFile, values.csvFile.name);

    console.log('values', values);
    console.log('values.csvFile', values.csvFile);
    console.log('formData', formDataObj);

    mutationSubmitSurvey.mutate(formDataObj);
  };

  return (
    <Box m='20px'>
      <Header title='New Survey' subtitle='Load survey CSV Data from Google Forms' />

      <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display='grid'
              gap='30px'
              gridTemplateColumns='repeat(4, minmax(0, 1fr))'
              sx={{
                '& > div': 'span 4',
              }}
            >
              <Select
                type='text'
                name='academicYear'
                value={values.academicYear}
                label='Academic Year'
                onChange={handleChange}
                sx={{ gridColumn: 'span 4' }}
              >
                <MenuItem value={'0'}>-- Academic Year --</MenuItem>
                <MenuItem value={'2022/2023'}>2022/2022</MenuItem>
                <MenuItem value={'2022/2023'}>2022/2023</MenuItem>
                <MenuItem value={'2023/2024'}>2023/2024</MenuItem>
                <MenuItem value={'2024/2025'}>2024/2025</MenuItem>
              </Select>

              <Select
                type='text'
                name='semester'
                value={values.semester}
                label='Semester'
                onChange={handleChange}
                sx={{ gridColumn: 'span 4' }}
              >
                <MenuItem value={'0'}>-- Semester --</MenuItem>
                <MenuItem value={'winter'}>Winter</MenuItem>
                <MenuItem value={'summer'}>Summer</MenuItem>
              </Select>

              <Select
                type='text'
                name='branch'
                value={values.branch}
                label='Branch'
                onChange={handleChange}
                sx={{ gridColumn: 'span 4' }}
              >
                <MenuItem value={'0'}>-- Branch --</MenuItem>
                <MenuItem value={'solec'}>Solec</MenuItem>
                <MenuItem value={'nowowiejska'}>Nowowiejska</MenuItem>
              </Select>

              <Select
                type='text'
                name='class'
                value={values.class}
                label='Class'
                onChange={handleChange}
                sx={{ gridColumn: 'span 4' }}
              >
                <MenuItem value={'0'}>-- Class --</MenuItem>
                {classData.map((c) => {
                  return (
                    <MenuItem key={c.id} value={c.id}>
                      {c.code}
                    </MenuItem>
                  );
                })}
              </Select>

              <Select
                type='text'
                name='teacher'
                value={values.teacher}
                label='Teacher'
                onChange={handleChange}
                sx={{ gridColumn: 'span 4' }}
              >
                <MenuItem value={0}>-- Select Teacher --</MenuItem>
                {teachersData.map((t) => {
                  return (
                    <MenuItem key={t.id} value={t.id}>
                      {t.firstName} {t.lastName}
                    </MenuItem>
                  );
                })}
              </Select>

              <div style={{ minWidth: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Input
                    id='raised-button-file'
                    fullWidth
                    variant='filled'
                    type='file'
                    label='Forms CSV'
                    onBlur={handleBlur}
                    onChange={(e) => {
                      if (e.currentTarget?.files.length > 0) {
                        setFieldValue('csvFile', e.currentTarget.files[0]);
                      }
                    }}
                    name='csvFile'
                    inputProps={{ accept: 'text/csv' }}
                    error={!!touched.csvFile && !!errors.csvFile}
                    sx={{ display: 'none' }}
                  />

                  <label htmlFor='raised-button-file'>
                    <Button sx={{ whiteSpace: 'nowrap' }} color='secondary' variant='contained' component='span'>
                      Select CSV File
                    </Button>
                  </label>
                  <Typography ml='10px' sx={{ whiteSpace: 'nowrap' }}>
                    {values.csvFile?.name}
                  </Typography>
                  {/* {errors.csvFile & <Typography>'Select a valid CSV file.'</Typography>} */}
                </Box>
              </div>
            </Box>
            <Box display='flex' justifyContent='end' mt='20px'>
              <Button type='submit' color='secondary' variant='contained'>
                Submit Survey Data
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

//const csvFileExtRegex = /^.*.(csv)$/;

const checkoutSchema = yup.object().shape({
  academicYear: yup.string().required('required'),
  semester: yup.string().required('required'),
  class: yup.string().required('required'),
  teacher: yup.string().required('required'),
  csvFile: yup.string().required('required'),
});

const initialValues = {
  academicYear: '0',
  semester: '0',
  class: '0',
  branch: '0',
  teacher: '0',
  csvFile: '',
};

export default NewSurveyForm;
