import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import CustomGridToolbar from '../../components/charts/CustomGridToolbar';
import { mockDataTeachers } from '../../data/mockData';
import Header from '../../components/Header';
import { useTheme } from '@mui/material';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner } from '../global/LoadingSpinner';
import { ApiContext } from '../../components/providers/ApiProvider';
import { useMutation, useQuery } from 'react-query';
import { useContext, useEffect, useRef, useState } from 'react';
import SyncIcon from '@mui/icons-material/Sync';
import LoadingButton from '@mui/lab/LoadingButton';
import { ThirtyFpsSharp } from '@mui/icons-material';

const Teachers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [teachersData, setTeachersData] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [teacherToEdit, setTeacherToEdit] = useState({});
  const api = useContext(ApiContext);
  const navigate = useNavigate();

  const { isLoading, refetch: refetchTeachers } = useQuery('query-teachers', {
    onSuccess: (res) => setTeachersData(res.data),
    onError: (err) => console.error('err', err),
  });

  useEffect(() => {
    setTeachersData(api.queryClient.getQueriesData('query-teachers')?.data || []);
  }, []);

  const mutationSync = useMutation({
    mutationKey: ['mutation-teachers-sync'],
    onSuccess: (res) => refetchTeachers(),
    onError: (err) => console.error('sync error!', err),
  });

  const mutationUpdateTeacher = useMutation({
    mutationKey: ['mutation-teacher-update'],
    onSuccess: (res) => refetchTeachers(),
    onError: (err) => console.error('teacher update error!', err),
  });

  const editTeacher = function (teacherData) {
    console.log('teacherData: ', teacherData);
    setTeacherToEdit(teacherData);
    setIsEditOpen(true);
  };

  const handleEditClose = () => {
    setIsEditOpen(false);
    setTeacherToEdit({});
  };

  const handleEditSave = (teacherUpdates) => {
    setIsEditOpen(false);
    console.log('updates: ', teacherUpdates);
    // todo - pass data and use some loading spinner to wait?
    mutationUpdateTeacher.mutate({
      teacherId: teacherToEdit.id,
      teacherData: teacherUpdates,
    });
  };

  // const testEditTeacher = (teacherData) => {
  //   const testTeacherData = { ...teacherData };
  //   testTeacherData.subject = 'Math';
  //   testTeacherData.branches = ['Nowowiejska'];
  //   console.log('testTeacherData', testTeacherData);
  //   setTeacherToEdit(testTeacherData);
  //   setIsEditOpen(true);
  // };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.8,
      renderCell: ({ row: { firstName, lastName, id } }) => {
        return (
          <Button
            sx={{ minWidth: '100%' }}
            variant='contained'
            startIcon={<VisibilityIcon />}
            onClick={() => navigate(`/teachers/${id}`)}
          >
            {firstName} {lastName}
          </Button>
        );
      },
    },
    {
      field: 'branches',
      headerName: 'Branch',
      flex: 1,
    },
    {
      field: 'subject',
      headerName: 'Subject',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
      renderCell: ({ row }) => {
        const role = row.role;
        return (
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Box
              flex
              width='60%'
              m='0 auto'
              p='5px'
              display='flex'
              justifyContent='center'
              backgroundColor={
                role === 'admin'
                  ? colors.greenAccent[600]
                  : role === 'manager'
                  ? colors.greenAccent[700]
                  : colors.greenAccent[700]
              }
              borderRadius='4px'
            >
              {role === 'admin' && <AdminPanelSettingsOutlinedIcon />}
              {role === 'manager' && <SecurityOutlinedIcon />}
              {role === 'user' && <LockOpenOutlinedIcon />}
              <Typography color={colors.grey[100]} sx={{ ml: '5px' }}>
                {role}
              </Typography>
            </Box>
            <Button size='small' color='secondary' onClick={() => editTeacher(row)} variant='contained'>
              <ModeEditOutlineIcon />
            </Button>
          </Grid>
        );
      },
    },
  ];

  return (
    <>
      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <Box m='20px'>
          <Header title='Teachers' subtitle="List of 2SLO's Teachers" />
          <LoadingButton
            size='small'
            color='secondary'
            onClick={mutationSync.mutate}
            loading={mutationSync.isLoading}
            loadingPosition='start'
            startIcon={<SyncIcon />}
            variant='contained'
          >
            <span>Sync</span>
          </LoadingButton>

          <Box
            m='40px 0 0 0'
            height='75vh'
            sx={{
              '& .MuiDataGrid-root': {
                border: 'none',
              },
              '& .MuiDataGrid-cell': {
                borderBottom: 'none',
              },
              '& .name-column--cell': {
                color: colors.greenAccent[300],
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: colors.blueAccent[700],
                borderBottom: 'none',
              },
              '& .MuiDataGrid-virtualScroller': {
                backgroundColor: colors.primary[400],
              },
              '& .MuiDataGrid-footerContainer': {
                borderTop: 'none',
                backgroundColor: colors.blueAccent[700],
              },
              '& .MuiCheckbox-root': {
                color: `${colors.greenAccent[200]} !important`,
              },
              '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid
              rows={teachersData}
              columns={columns}
              components={{ Toolbar: CustomGridToolbar }}
              hideFooterSelectedRowCount={true}
            />
          </Box>
        </Box>
      )}

      {isEditOpen && (
        <TeacherEditDialog onSave={handleEditSave} onClose={handleEditClose} teacherData={teacherToEdit} />
      )}
    </>
  );
};

const TeacherEditDialog = ({ onClose, onSave, teacherData }) => {
  const subjectRef = useRef();
  const [isInNowowiejska, setIsInNowowiejska] = useState(teacherData.branches?.includes('Nowowiejska') || false);
  const [isInSolec, setIsInSolec] = useState(teacherData.branches?.includes('Solec') || false);

  const onDialogSave = () => {
    console.log('subject inside', subjectRef.current.value);
    const newSubject = subjectRef.current.value;
    const subjectEdited =
      !(teacherData.subject === undefined && newSubject === '') && teacherData.subject !== subjectRef.current.value;

    const nBranchChange =
      (teacherData.branches?.includes('Nowowiejska') && !isInNowowiejska) ||
      (!teacherData.branches?.includes('Nowowiejska') && isInNowowiejska);
    const sBranchChange =
      (teacherData.branches?.includes('Solec') && !isInSolec) ||
      (!teacherData.branches?.includes('Solec') && isInSolec);

    const teacherUpdates = {};
    if (subjectEdited) {
      teacherUpdates['subject'] = newSubject;
    }
    if (nBranchChange || sBranchChange) {
      const branches = [];
      if (isInNowowiejska) branches.push('Nowowiejska');
      if (isInSolec) branches.push('Solec');
      teacherUpdates['branches'] = branches;
    }

    console.log('was edited: ', teacherUpdates);

    if (!subjectEdited && !nBranchChange && !sBranchChange) {
      onClose();
    } else {
      onSave(teacherUpdates);
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth={'md'} open={true} onClose={onClose}>
      <DialogTitle>Edit Teacher</DialogTitle>
      <DialogContent>
        <DialogContentText>{"Update Teacher's details"}</DialogContentText>
        <Grid container direction='column' justifyContent='space-between'>
          <TextField
            sx={{ marginTop: 3 }}
            label='Name'
            defaultValue={teacherData.firstName + ' ' + teacherData.lastName}
            disabled
          />
          <TextField sx={{ marginTop: 3 }} label='Email' defaultValue={teacherData.email} disabled />
          <TextField
            sx={{ marginTop: 3 }}
            label='Subject'
            inputRef={subjectRef}
            defaultValue={teacherData.subject || ''}
          />

          <FormControl sx={{ marginTop: 3 }} component='fieldset' variant='standard'>
            <FormLabel component='legend'>Choose Branches</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isInNowowiejska}
                    onChange={() => {
                      setIsInNowowiejska((prevState) => !prevState);
                    }}
                    name='nowowiejska'
                  />
                }
                label='Nowowiejska'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isInSolec}
                    onChange={() => {
                      setIsInSolec((prevState) => !prevState);
                    }}
                    name='solec'
                  />
                }
                label='Solec'
              />
            </FormGroup>
            <FormHelperText>You can select both</FormHelperText>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onDialogSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Teachers;
