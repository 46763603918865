import axios from 'axios';
import { QueryClient } from 'react-query';
import { loginRequest } from '../config/authConfig';

// TODO - add url to the .env file
const baseURL = 'http://localhost:3333';

const urlPathClassBase = '/class';
const urlPathTeacherBase = '/teacher';
const urlPathSyncTeachers = urlPathTeacherBase + '/sync';
const urlPathSubmitSurveyData = baseURL + '/survey/submit';
const urlPathGetUserGroups = '/user/groups';

class ApiClient {
  'use strict';
  msalInstance;
  api;
  queryClient = new QueryClient();

  constructor(msalInstance, queryClient) {
    this.msalInstance = msalInstance;
    this.api = axios.create({
      baseURL: baseURL,
      headers: {
        'Content-type': 'application/json',
      },
    });

    this.api.interceptors.request.use(async function (config) {
      const tokenResponse = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: msalInstance.getActiveAccount(),
      });

      config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;

      return config;
    });

    this.queryClient = queryClient;
    this.initQueryClientDefaults();
  }

  createClass = function (classData) {
    return this.api.post(urlPathClassBase, classData);
  };

  getClasses = function () {
    return this.api.get(urlPathClassBase);
  };

  updateClass = function (classId, classData) {
    return this.api.patch(`${urlPathClassBase}/${classId}`, classData);
  };

  syncTeachers = function () {
    return this.api.post(urlPathSyncTeachers);
  };

  getTeachers = function () {
    return this.api.get(urlPathTeacherBase);
  };

  updateTeacher = function (updateData) {
    return this.api.patch(`${urlPathTeacherBase}/${updateData.teacherId}`, updateData.teacherData);
  };

  submitSurveyData = function (surveyData) {
    return this.api.post(urlPathSubmitSurveyData, surveyData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  getUserGroups = async function () {
    return this.api.get(urlPathGetUserGroups);
  };

  initQueryClientDefaults() {
    this.queryClient.setQueryDefaults('query-teachers', { queryFn: () => this.getTeachers(), enabled: true });
    this.queryClient.setQueryDefaults('query-classes', { queryFn: () => this.getClasses(), enabled: true });

    // TODO add Sentry
    this.queryClient.setDefaultOptions({
      queries: {
        onError: (err) => console.error('err', err),
      },
      mutations: {
        onError: (err) => console.error('err', err),
      },
    });

    this.queryClient.setMutationDefaults(['mutation-teachers-sync'], {
      mutationFn: () => this.syncTeachers(),
    });

    this.queryClient.setMutationDefaults(['mutation-teacher-update'], {
      mutationFn: (teacherId, teacherData) => this.updateTeacher(teacherId, teacherData),
    });

    this.queryClient.setMutationDefaults(['mutation-class-create'], {
      mutationFn: (classData) => this.createClass(classData),
    });

    this.queryClient.setMutationDefaults(['mutation-class-update'], {
      mutationFn: (classId, classData) => this.updateClass(classId, classData),
    });

    this.queryClient.setMutationDefaults(['mutation-submit-survey'], {
      mutationFn: (surveyData) => this.submitSurveyData(surveyData),
    });
  }
}

export default ApiClient;
