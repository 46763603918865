import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config/authConfig';
import { MsalEvents } from './components/azure/MsalEvents';
import { ApiContextWrapper } from './components/providers/ApiProvider';

import { QueryClient } from 'react-query';

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      {/* <MsalEvents /> */}
      <ApiContextWrapper msalInstance={msalInstance} queryClient={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApiContextWrapper>
    </MsalProvider>
  </React.StrictMode>
);
