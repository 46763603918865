import { Box, Button } from '@mui/material';
import Header from '../../../components/Header';
import SurveyChart from '../../../components/charts/SurveyChart';
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { tokens } from '../../../theme';
import { useTheme } from '@emotion/react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import QuickChart from 'quickchart-js';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfMakeUnicode from 'pdfmake-unicode';

import { chartDef, docDefinition } from '../../../data/mocks';

pdfMake.vfs = pdfMakeUnicode.pdfMake.vfs;

const SingleSurvey = () => {
  // useParam and get the data - then pass to SurveyChart
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const exportAsPdf = () => {
    const myChart = new QuickChart();
    const scaleUpBy = 1.5;
    myChart
      .setConfig(chartDef)
      .setWidth(scaleUpBy * 500)
      .setHeight(scaleUpBy * 300)
      .setDevicePixelRatio(3);
    const url = myChart.getUrl();
    console.log(url);

    pdfMake.createPdf(docDefinition(url)).open();
  };

  return (
    <Box m='20px'>
      <Header
        title='Imię i nazwisko: Rok akademicki - semestr - klasa'
        subtitle='Wyniki ogólne i dystrybucja odpowiedzi'
      />

      <Button variant='contained' startIcon={<PictureAsPdfIcon />} onClick={exportAsPdf}>
        Export as PDF
      </Button>

      <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', minHeight: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            sx={{
              height: '100%',
              width: '100%',
              '& MuiTab-root.Mui-selected': {
                color: 'white !important',
              },
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
            indicatorColor='secondary'
            textColor='secondary'
          >
            {questions.map((q) => (
              <Tab key={q.num} label={q.num === 0 ? 'All' : q.num} {...a11yProps(q.num)} />
            ))}
          </Tabs>
        </Box>

        {questions.map((q) => (
          <TabPanel key={q.num} style={{ flexGrow: 1 }} value={value} index={q.num}>
            <SurveyChartTab question={q} showAll={q.num === 0} />
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

const questions = [
  {
    num: 0,
    index: 0,
    title: 'Wyniki ogólne',
    description: 'Liczba ankiet: 18, Liczebność klasy: 22, Wartość jednego głosu: 0.04 (???)',
  },
  {
    num: 1,
    index: 0,
    title: 'PRZEKAZYWANIE WIEDZY',
    description:
      'Na lekcjach dowiaduję się dużo nowych rzeczy, czuję, że nauczyciel jest kompetentny w swoim przedmiocie',
  },
  {
    num: 2,
    index: 1,
    title: 'ATRAKCYJNOŚĆ PRZEKAZU',
    description: 'Nauczyciel przekazuje wiedzę w ciekawy sposób, inspiruje',
  },
  {
    num: 3,
    index: 2,
    title: 'ORGANIZACJA LEKCJI',
    description: 'Mam wrażenie, że zajęcia są dobrze zaplanowane, uporządkowane, nauczyciel wie, do czego zmierza',
  },
  {
    num: 4,
    index: 3,
    title: 'ATMOSFERA NA LEKCJI',
    description:
      'Na lekcjach panuje atmosfera sprzyjająca pracy, nauczyciel potrafi pracować z grupą, reaguje na to, co się dzieje w klasie',
  },
  {
    num: 5,
    index: 4,
    title: 'ŻYCZLIWE PODEJŚCIE DO UCZNIÓW',
    description: 'Czuję, że mogę poprosić nauczyciela o pomoc i radę',
  },
  {
    num: 6,
    index: 5,
    title: 'STOSUNEK DO UCZNIÓW I UCZENNIC',
    description:
      'Nauczyciel okazuje uczniom i uczennicom należny im szacunek, nie przekracza granic, na zajęciach czuję się bezpiecznie',
  },
  {
    num: 7,
    index: 6,
    title: 'BEZSTRONNOŚĆ',
    description: 'Nauczyciel jest bezstronny, nie narzuca uczniom własnych przekonań, szanuje ich poglądy',
  },
  {
    num: 8,
    index: 7,
    title: 'OTWARTOŚĆ',
    description: 'Nauczyciel jest otwarty na potrzeby uczniów, potrafi pójść na kompromis, przyjąć uzasadnioną krytykę',
  },
  {
    num: 9,
    index: 8,
    title: 'ZAANGAŻOWANIE',
    description: 'Nauczyciel angażuje się w prowadzenie lekcji',
  },
  {
    num: 10,
    index: 9,
    title: 'STYMULOWANIE DO PRACY',
    description: 'Nauczyciel stawia wymagania, stymuluje do pracy, zachęca do robienia trudnych rzeczy',
  },
  {
    num: 11,
    index: 10,
    title: 'OCENIANIE',
    description: 'Nauczyciel ocenia sprawiedliwie, wiem z czego wynikają moje oceny',
  },
  {
    num: 12,
    index: 11,
    title: 'INFORMACJE ZWROTNE',
    description: 'Nauczyciel udziela użytecznych dla mnie informacji zwrotnych',
  },
  {
    num: 13,
    index: 12,
    title: 'PRZEJRZYSTOŚĆ REGUŁ',
    description:
      'Nauczyciel jasno formułuje swój system oceniania, czuję się dobrze poinformowany/a i wiem, czego się mogę spodziewać',
  },
];

function SurveyChartTab(props) {
  const { question, showAll } = props;

  return (
    <>
      <Typography pb={5} variant='h5' color='secondary'>
        {question.title} - {question.description}
      </Typography>
      <SurveyChart showAll={showAll} />
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default SingleSurvey;
