import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../config/authConfig';
import { Button } from '@mui/material';

const LogInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });

    instance.addEventCallback;
  };

  return (
    <>
      <Button onClick={handleLogin} color='secondary' variant='contained'>
        Sign in
      </Button>
    </>
  );
};

export default LogInButton;
