import { useContext, useEffect, useRef, useState } from 'react';
import { AuthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import UserContext from '../../components/providers/UserContext';
import Sidebar from '../../components/sidebar/Sidebar';
import Topbar from './Topbar';
import Surveys from '../surveys/all';
import SingleSurvey from '../surveys/singleSurvey';
import Teachers from '../teachers';
import TeacherSurveys from '../surveys/teacherSurveys';
import NewTeacherForm from '../forms/NewTeacherForm';
import NewSurveyForm from '../forms/NewSurveyForm';
import FAQ from '../faq/FAQ';
import AdminFAQ from '../faq/AdminFAQ';
import { LoadingSpinner } from './LoadingSpinner';
import { ApiContext } from '../../components/providers/ApiProvider';
import { useQuery } from 'react-query';
import Classes from '../classes';

const AuthorizedApp = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const [userData, setUserData] = useState({});

  const accountInfo = useAccount();
  const api = useContext(ApiContext);

  // useEffect(() => {
  //   console.log('api', api);
  // }, []);

  const { isLoading } = useQuery('query-user-groups', () => api.getUserGroups(), {
    enabled: true,
    staleTime: 10 * 60 * 1000,
    onSuccess: (res) => {
      console.log('success', res);

      setUserData({
        user: {
          info: accountInfo,
          groups: res.data.groups || [],
        },
      });
    },
    onError: (err) => {
      console.error('err', err);
    },
  });

  return (
    // <UserContext.Provider value={userData}></UserContext.Provider>
    <>
      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <div className='app'>
          <Sidebar isSidebar={isSidebar} />
          <main className='content'>
            <Topbar setIsSidebar={setIsSidebar} />

            <Routes>
              <Route path='/' element={<Navigate to={'/surveys'} replace />} />
              <Route path='/faq' element={<FAQ />} />

              <Route path='/teachers' element={<ProtectedRoute letIn={['Survey.Management', 'Survey.Admin']} />}>
                {/* todo - use id and show for one teacher */}
                <Route path=':userId' element={<TeacherSurveys />} />
                <Route path='' element={<Teachers />} />
              </Route>

              <Route path='/classes' element={<ProtectedRoute letIn={['Survey.Management', 'Survey.Admin']} />}>
                {/* todo - use id and show for one class */}
                <Route path=':classId' element={<TeacherSurveys />} />
                <Route path='' element={<Classes />} />
              </Route>

              <Route path='/surveys'>
                <Route path=':surveyId' element={<SingleSurvey />} />
                <Route path='' element={<Surveys />} />
              </Route>

              {/* <Route path='/teacherForm' element={<ProtectedRoute letIn={['Survey.Admin']} />}>
                <Route path='' element={<NewTeacherForm />} />
              </Route> */}

              <Route path='/newSurveyForm' element={<ProtectedRoute letIn={['Survey.Admin']} />}>
                <Route path='' element={<NewSurveyForm />} />
              </Route>

              <Route path='/admin-faq' element={<ProtectedRoute letIn={['Survey.Admin']} />}>
                <Route path='' element={<AdminFAQ />} />
              </Route>
            </Routes>
          </main>
        </div>
      )}
    </>
  );
};

export default AuthorizedApp;

const ProtectedRoute = ({ letIn }) => {
  const userContext = useContext(UserContext);

  const [routeAllowed, setRouteAllowed] = useState(true);

  useEffect(() => {
    if (letIn?.length > 0 && userContext?.user?.groups?.length > 0) {
      const hasRequiredGroup = letIn.some((g) => userContext.user.groups.includes(g));
      setRouteAllowed(hasRequiredGroup);
    }
  }, []);

  return routeAllowed ? <Outlet /> : null;
};
