import { MenuItem } from 'react-pro-sidebar';
import { Box, IconButton, Typography } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { SidebarAvatar } from './SidebarAvatar';

export function SidebarHeader({ headerTitle, setIsCollapsed, isCollapsed, colors }) {
  return (
    <>
      <MenuItem
        onClick={() => setIsCollapsed(!isCollapsed)}
        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
        style={{
          margin: '10px 0 20px 0',
          color: colors.grey[100],
        }}
      >
        {!isCollapsed && (
          <Box display='flex' justifyContent='space-between' alignItems='center' ml='15px'>
            <Typography variant='h3' color={colors.grey[100]}>
              {headerTitle}
            </Typography>
            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <MenuOutlinedIcon />
            </IconButton>
          </Box>
        )}
      </MenuItem>
      {!isCollapsed && <SidebarAvatar />}
    </>
  );
}
