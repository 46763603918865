import { useContext, useEffect, useState } from 'react';
import { MenuItem } from 'react-pro-sidebar';
import { Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { tokens } from '../../theme';
import UserContext from '../providers/UserContext';

export const Item = ({ title, to, icon, selected, setSelected, requiredGroup }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const userContext = useContext(UserContext);
  const [shouldRender, setShouldRender] = useState(true);

  useEffect(() => {
    if (requiredGroup?.length > 0 && userContext?.user?.groups?.length > 0) {
      // todo
      const hasRequiredGroup = requiredGroup.some((g) => userContext.user.groups.includes(g));
      setShouldRender(hasRequiredGroup);
    }
  }, []);

  return shouldRender ? (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  ) : null;
};
