import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ApiClient from '../../data/api';

const ApiContext = React.createContext({});

const ApiContextWrapper = (props) => {
  const [client] = React.useState(() => {
    return new ApiClient(props.msalInstance, props.queryClient);
  });

  return (
    <QueryClientProvider client={props.queryClient}>
      <ApiContext.Provider value={client}>{props.children}</ApiContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export { ApiContext, ApiContextWrapper };
