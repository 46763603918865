import { Box, CircularProgress } from '@mui/material';

const LoadingSpinner = () => {
  return (
    <div className='app'>
      <main className='content'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress color='secondary' />
        </Box>
      </main>
    </div>
  );
};

export { LoadingSpinner };
