import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../../theme';
import { mockDataSurveys } from '../../../data/mockData';
import CustomGridToolbar from '../../../components/charts/CustomGridToolbar';
import Header from '../../../components/Header';
import { useNavigate } from 'react-router-dom';

const Surveys = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  // TODO - check roles and show only mine if not ADMIN / MANAGEMENT

  const columns = [
    {
      field: 'id',
      headerName: '-',
      headerAlign: 'center',
      flex: 0.8,
      renderCell: ({ row: { name, id } }) => {
        return (
          <Button
            sx={{ minWidth: '100%' }}
            variant='contained'
            startIcon={<VisibilityIcon />}
            onClick={() => navigate(`/surveys/${id}`)}
          >
            View Results
          </Button>
        );
      },
    },
    {
      field: 'academicYear',
      headerName: 'Academic Year',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'semester',
      headerName: 'Semester',
      flex: 1,
      renderCell: (params) => (
        <Typography>{params.row.semester.charAt(0).toUpperCase() + params.row.semester.slice(1)}</Typography>
      ),
    },
    {
      field: 'teacher',
      headerName: 'Teacher',
      flex: 1,
    },
    {
      field: 'class',
      headerName: 'Class',
      flex: 1,
    },
  ];

  return (
    <Box m='20px'>
      <Header title='Surveys' subtitle="List of all teacher's surveys" />
      <Box
        m='40px 0 0 0'
        height='75vh'
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={mockDataSurveys}
          columns={columns}
          components={{ Toolbar: CustomGridToolbar }}
          hideFooterSelectedRowCount={true}
        />
      </Box>
    </Box>
  );
};

export default Surveys;
