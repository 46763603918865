import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const labels = ['2', '1', '0', '-1', '-2'];
const labelsAll = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'];

export const data = {
  labels,
  datasets: [
    {
      type: 'bar',
      label: 'Distribution',
      borderColor: 'rgb(40, 240, 140)',
      backgroundColor: 'rgb(40, 240, 140, 0.7)',
      borderWidth: 2,
      borderRadius: 5,
      borderSkipped: false,
      data: [7, 3, 4, -4, -6],
    },
  ],
};

//labels.map(() => faker.datatype.number({ min: -0, max: 8 })),

export const dataAll = {
  labels: labelsAll,
  datasets: [
    {
      type: 'bar',
      label: 'All',
      borderColor: 'rgb(40, 240, 140)',
      backgroundColor: 'rgb(40, 240, 140, 0.7)',
      borderWidth: 2,
      borderRadius: 5,
      borderSkipped: false,
      data: labelsAll.map(() => faker.datatype.float({ min: -2, max: 2 })),
    },
  ],
};

const opt = {
  plugins: {
    legend: {
      display: false,
    },
  },
  interaction: {
    mode: 'x',
    intersect: false,
  },
};

const optHorizontal = {
  responsive: true,
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 9,
    },
  },
  plugins: {
    legend: {
      display: false,
    },

    tooltip: {
      callbacks: {
        label: function (context) {
          console.log(context);
          let label = 'Mogę tu wyświetlić cokolwiek';
          return label;
        },
      },
    },
  },
};

export default function SurveyChart({ showAll }) {
  useEffect(() => {
    console.log(labelsAll.map(() => faker.datatype.float({ min: -2, max: 2 })));
  }, []);
  return <Chart type='bar' height={100} data={showAll ? dataAll : data} options={showAll ? opt : optHorizontal} />;
}
