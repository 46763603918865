import 'react-pro-sidebar/dist/css/styles.css';

import { useState } from 'react';
import { ProSidebar, Menu } from 'react-pro-sidebar';

import { Box, Typography, useTheme } from '@mui/material';
import {
  PeopleOutlined,
  ReceiptOutlined,
  PersonOutlined,
  ListAlt,
  HelpOutlineOutlined,
  Class,
} from '@mui/icons-material';

import { tokens } from '../../theme';
import { Item } from './Item';
import { SidebarHeader } from './SidebarHeader';
import { SidebarStyleWrapper } from './SidebarStyleWrapper';

const Sidebar = ({ user }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState('Surveys');

  return (
    <SidebarStyleWrapper>
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape='square'>
          <SidebarHeader
            headerTitle={'2SLO SURVEYS'}
            setIsCollapsed={setIsCollapsed}
            isCollapsed={isCollapsed}
            colors={colors}
          />

          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            <Typography variant='h6' color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
              Admin
            </Typography>
            <Item
              title='Teachers'
              to='/teachers'
              icon={<PeopleOutlined />}
              selected={selected}
              setSelected={setSelected}
              requiredGroup={['Survey.Admin', 'Survey.Management']}
            />

            <Item
              title='Classes'
              to='/classes'
              icon={<Class />}
              selected={selected}
              setSelected={setSelected}
              requiredGroup={['Survey.Admin', 'Survey.Management']}
            />
            {/* <Item
              title='Tacher Profile Form'
              to='/teacherForm'
              icon={<PersonOutlined />}
              selected={selected}
              setSelected={setSelected}
              requiredGroup={['Survey.Admin']}
            /> */}
            <Item
              title='New Survey Form'
              to='/newSurveyForm'
              icon={<ListAlt />}
              selected={selected}
              setSelected={setSelected}
              requiredGroup={['Survey.Admin']}
            />
            <Item
              title='Admin FAQ'
              to='/faq'
              icon={<HelpOutlineOutlined />}
              selected={selected}
              setSelected={setSelected}
              requiredGroup={['Survey.Admin']}
            />
            <Typography variant='h6' color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
              Pages
            </Typography>
            <Item
              title='Surveys'
              to='/surveys'
              icon={<ReceiptOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title='FAQ Page'
              to='/faq'
              icon={<HelpOutlineOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </SidebarStyleWrapper>
  );
};

export default Sidebar;

/*

//import 'react-pro-sidebar/dist/css/styles.css';

import { useState } from 'react';
import { Sidebar, Menu } from 'react-pro-sidebar';

import { Box, Typography, useTheme } from '@mui/material';
import {
  PeopleOutlined,
  ReceiptOutlined,
  PersonOutlined,
  ListAlt,
  HelpOutlineOutlined,
  Class,
} from '@mui/icons-material';

import { tokens } from '../../theme';
import { Item } from './Item';
import { SidebarHeader } from './SidebarHeader';
import { SidebarStyleWrapper } from './SidebarStyleWrapper';

const AppSidebar = ({ isSidebar, user }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(isSidebar);
  const [selected, setSelected] = useState('Surveys');

  return (
    // <SidebarStyleWrapper>
    <Sidebar collapsed={isCollapsed}>
      <Menu iconShape='square'>
        <SidebarHeader
          headerTitle={'2SLO SURVEYS'}
          setIsCollapsed={setIsCollapsed}
          isCollapsed={isCollapsed}
          colors={colors}
        />

        <Box paddingLeft={isCollapsed ? undefined : '10%'}>
          <Typography variant='h6' color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
            Admin
          </Typography>
          <Item
            title='Teachers'
            to='/teachers'
            icon={<PeopleOutlined />}
            selected={selected}
            setSelected={setSelected}
            requiredGroup={['Survey.Admin', 'Survey.Management']}
          />

          <Item
            title='Classes'
            to='/teachers'
            icon={<Class />}
            selected={selected}
            setSelected={setSelected}
            requiredGroup={['Survey.Admin', 'Survey.Management']}
          />
          <Item
            title='Tacher Profile Form'
            to='/teacherForm'
            icon={<PersonOutlined />}
            selected={selected}
            setSelected={setSelected}
            requiredGroup={['Survey.Admin']}
          />
          <Item
            title='New Survey Form'
            to='/newSurveyForm'
            icon={<ListAlt />}
            selected={selected}
            setSelected={setSelected}
            requiredGroup={['Survey.Admin']}
          />
          <Item
            title='Admin FAQ'
            to='/faq'
            icon={<HelpOutlineOutlined />}
            selected={selected}
            setSelected={setSelected}
            requiredGroup={['Survey.Admin']}
          />
          <Typography variant='h6' color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
            Pages
          </Typography>
          <Item
            title='Surveys'
            to='/surveys'
            icon={<ReceiptOutlined />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            title='FAQ Page'
            to='/faq'
            icon={<HelpOutlineOutlined />}
            selected={selected}
            setSelected={setSelected}
          />
        </Box>
      </Menu>
    </Sidebar>
    // </SidebarStyleWrapper>
  );
};

export default AppSidebar;

*/
